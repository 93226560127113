import * as API from './baseConst';

export const catalogAPI = {
  getDefectsCatalog: function (id) {
    if (id) return fetch(`${API.BASE_URL}/defects_guide/${id}`);
    return fetch(`${API.BASE_URL}/defects_guide/`);
  },
  createDefectItem: function (jsonObject) {
    return fetch(`${API.BASE_URL}/defects_guide/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonObject),
    });
  },
  updateSurface: function (data) {
    return fetch(`${API.BASE_URL}/surfaces_guide/update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  getSurfaceByID: function (id) {
    if (id) return fetch(`${API.BASE_URL}/surfaces_guide/${id}`);
  },
  getSurfaceDefectsByID: function (id) {
    if (id) return fetch(`${API.BASE_URL}/surfaces_guide/${id}`);
  },
  updateDefectsForSurface: function (data) {
    return fetch(`${API.BASE_URL}/defects_guide/update`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //==============================
  // Справочник видов экспертиз
  //==============================
  getExpertisesTypeCatalog: function () {
    return fetch(`${API.BASE_URL}/exp_type_guide`);
  },
  // Дабавление нового вида экспертизы
  addNewExpertiseType: function (data) {
    return fetch(`${API.BASE_URL}/exp_type_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  // Обновить запись в справочнике видов экспертиз
  updateExpertiseTypeItem: function (data) {
    return fetch(`${API.BASE_URL}/exp_type_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //==============================
  // Справочник подвидов экспертиз
  //==============================
  //Получить справочник подвидов экспертиз
  getSubTypeExpertiseCatalog: function () {
    return fetch(`${API.BASE_URL}/exp_subtype_guide`);
  },
  //Добавить новый подвид в каталог
  addNewSubTypeExpertiseItem: function (data) {
    return fetch(`${API.BASE_URL}/exp_subtype_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  /**
   * Получить все подтипы экспертизы, по ID типа экспертизы
   */
  getAllSubtypesById:function(id) {
    return fetch(`${API.BASE_URL}/exp_subtype_guide/by_exp_type_guide/${id}`);
  },
  //==============================
  // Справочник регионов
  //==============================
  //Получить справочник регионов
  getRegionsCatalog: function () {
    return fetch(`${API.BASE_URL}/region_guide`);
  },
  // Добавление нового региона в справочник
  addNewRegion: function (data) {
    return fetch(`${API.BASE_URL}/region_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  updateRegionItem: function (data) {
    return fetch(`${API.BASE_URL}/region_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //==============================
  // Справочник организаций
  //==============================
  //Получить справочник организаций
  getOrganizationCatalog: function () {
    return fetch(`${API.BASE_URL}/org_guide`);
  },
  //Добавление новой организации
  addNewOrganization: function (data) {
    return fetch(`${API.BASE_URL}/org_guide`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //Обновить запись в справочнике организация
  updateOrganizationItem: function (data) {
    return fetch(`${API.BASE_URL}/org_guide`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  //Получить регионы организации
  getOrganizationRegion: function (id) {
    return fetch(`${API.BASE_URL}/org_reg_guide2/by_id_org/${id}`);
  },
  //Привязать регион к организации
  addRegionForOrganization: function(data){
    return fetch(`${API.BASE_URL}/org_reg_guide2`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  },
  updateOrganizationRegion: function(data){
    return fetch(`${API.BASE_URL}/org_reg_guide2`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};