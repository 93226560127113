<template>
    <div class="gallery-container">
      <!-- Кнопка для листания влево -->
      <v-btn icon @click="scrollLeft" class="gallery-btn left">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <!-- Галерея изображений -->
      <div v-if="photos.length == 0" class="text-center flex">Фотографии отсутствуют</div>
      <div class="gallery-wrapper" v-if="photos.length > 0">
        <div class="gallery-list">



          <div class="gallery-item" v-for="(image, index) in photos" :key="index">
            <v-card rounded="" height="200" width="200" class="ma-4 pa-2">
            <!-- Картинка -->
            <v-img :src="image.photoPath" height="100%" width="100%" contain ></v-img>
            <!-- <img :src="image.photoPath"  /> -->

            <!-- Кнопка удаления -->
            <v-btn
              icon
              class="delete-btn"
              @click="removeImage(image.id)"
            >
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
                <v-btn
                  v-if="modeAdd && !isSelectedFoto(image.id)"
                  :disabled="storedSelectedPhoto.length == 2"
                  fab
                  small
                  color="primary"
                  dark
                  class="add-btn"
                  @click="addImageToDefect(image.id,image.photoPath)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                  <v-btn
                    v-if="modeAdd && isSelectedFoto(image.id)"
                    fab
                    small
                    color="green"
                    dark
                    class="add-btn"
                  >
                    <v-icon>mdi-check-bold</v-icon>
                  </v-btn>
              </v-card>
          </div>

        </div>
      </div>

      <!-- Кнопка для листания вправо -->
      <v-btn icon @click="scrollRight" class="gallery-btn right">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
</template>

<script>
export default {
  props:{
    modeAdd:{
      required: true,
      default: false
    }
  },
  computed:{
    photos(){
      return this.$store.getters.defectPhotoList;
    },
    storedSelectedPhoto(){
      return this.$store.getters.getDefectSelectedFotos
    }
  },
  data:()=>({
    selectedPhoto:[]
  }),
  methods: {
    scrollLeft() {
      const gallery = this.$el.querySelector('.gallery-wrapper');
      gallery.scrollBy({ left: -200, behavior: 'smooth' });
    },
    scrollRight() {
      const gallery = this.$el.querySelector('.gallery-wrapper');
      gallery.scrollBy({ left: 200, behavior: 'smooth' });
    },
    removeImage(id) {
      this.$emit('remove-image', id);
    },
    addImageToDefect(id,path){
      // if(this.selectedPhoto.length<2){
      //   this.selectedPhoto.push({ id: id, path: path });
      //   this.$store.dispatch("updateDefectSelectedFotos",this.selectedPhoto)
      // }
      if(this.storedSelectedPhoto.length<2){
        this.$store.commit("addDefectPhotoToSelectedArray", { id: id, path: path })
      }
    },
    isSelectedFoto(id){
      // for(let i=0;i<this.selectedPhoto.length;i++){
      //   if(this.selectedPhoto[i].id==id) return true;
      // }
      for (let i = 0; i < this.storedSelectedPhoto.length; i++) {
        if (this.storedSelectedPhoto[i].id == id) return true;
      }
      return false;
    }
  },
};
</script>

<style scoped>
/* Контейнер галереи */
.gallery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

/* Кнопки */
.gallery-btn {
  margin: 0 10px;
}

.gallery-wrapper {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  scroll-behavior: smooth;
}

/* Список галереи */
.gallery-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

/* Элемент галереи */
.gallery-item {
  position: relative;
  /*
  flex: 0 0 auto;
  width: 5cm;
  height: 5cm;
  */
  margin-right: 10px;
  overflow: hidden;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Кнопка удаления */
.delete-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  color: white;
}
.add-btn{
  position: absolute;
  bottom: 5px;
  right: 5px;
}
</style>