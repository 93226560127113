import { BASE_URL } from "./baseConst";
//https://expertise-develop-bse.amvera.io/version
export function createExpertise(data) {
  return fetch(
    `${BASE_URL}/expertise`,
    {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
}

export function updateExpertiseCommonInfo(expertiseData) {
  return fetch(`${BASE_URL}/expertises/edit`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(expertiseData),
  });
}

export function getExpertiseInfo(idExpertise) {
  return fetch(`${BASE_URL}/expertises/get?id=${idExpertise}`);
}

export function getAllInspObject(idExpertise) {
  return fetch(`${BASE_URL}/insp_objs?idExpertise=${idExpertise}`);
}

export function uploadFoto(idExpertise, formData) {
  console.log(formData);
  return fetch(`${BASE_URL}/insp_objs/add_photo?id=${idExpertise}`, {
    method: "POST",
    body: formData,
  });
}

export function removeObjInspection(id){
  return fetch(`${BASE_URL}/insp_objs/delete?id=${id}`, {
    method: "DELETE",
  });
}

export function getAllObjectsNames() {
  return fetch(`${BASE_URL}/insp_objs/get_names`);
}

export function createNewObjectInspection(formData){
  return fetch(`${BASE_URL}/insp_objs/create`, {
    method: "POST",
    body: formData,
  });
}

export function generateDocx(id){
  return fetch(`${BASE_URL}/insp_objs/table?idExpertise=${id}`);
}

export function getVersionApi(){
  return fetch(`${BASE_URL}/version`);
}

//Получить лог сервера
export function getLogs(){
  return fetch(`${BASE_URL}/logs`);
}
//Очистить логи
export function removeLogs(){
  return fetch(`${BASE_URL}/logs/delete`, {
    method: "POST",
  });
}
//Получить дефекты для объекта осмотра
export function getDefectsForObject(id){
  // return fetch(`${BASE_URL}/defects?idInspObj=${id}`);
  return fetch(`${BASE_URL}/defects_info?idInspObj=${id}&photoNotUse=false`);
}

export function uploadFotoDefect(formData) {
  return fetch(`${BASE_URL}/photos_defect/add_photo`, {
    method: "POST",
    body: formData,
  });
}

export function removePhotoDefectByID(id) {
  return fetch(`${BASE_URL}/photos_defect/${id}`, {
    method: "DELETE"
  });
}

//Добавляет новый элемент в справочник Поверхностей дефектов
export function addNewDefectSurface(formData){
  return fetch(`${BASE_URL}/surfaces_guide/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: formData,
  });
}
//Запрос всех поверхностей дефектов
export function getAllDefectSurfaces(){
  return fetch(`${BASE_URL}/surfaces_guide`);
}

//Выборка возможных дефектов для поверхности
export function getAllDefectsForSurface(id){
  return fetch(`${BASE_URL}/defects_guide/idSurface/${id}`);
}
//Добавление нового деекта для поверхности
export function createNewDefectForSurface(obj){
  return fetch(`${BASE_URL}/defects/add_defect`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  });
}

//Удалить дефект для объекта осмотра(Комната,Балкон и т.д)
export function removeDefectsFromIspObject(id) {
  return fetch(`${BASE_URL}/defects/${id}`, {
    method: "DELETE"
  });
}

//Обновить конкретный дефект у объекта осмотра
export function updateDefectForObject(obj) {
  return fetch(`${BASE_URL}/defects`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),
  });
}

//Получить все дефекты для экспертизы
export function getAllDefectsForExpertise(id){
  return fetch(`${BASE_URL}/defects/idExpertise?idExpertise=${id}`);
}

//Получить таблицу недостатков
export function getDefectsTableDoc(id){
  return fetch(`${BASE_URL}/defects/table/${id}`);
}