<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <DefectsSurface class="mt-4" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import DefectsSurface from '../components/Catalogs/DefectsSurface.vue';
export default {
    name: "CatalogSurfaces",
    components: {
        DefectsSurface,
    },
};
</script>
