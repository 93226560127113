import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Expertises from "../views/Expertises.vue";
import Expertise from "../views/Expertise.vue";
import Admin from "../views/Admin.vue";
import CatalogDefects from "../views/CatalogDefects.vue";
import CatalogSurfaces from "../views/CatalogSurfaces.vue";
import CatalogExpertiseTypes from "../views/CatalogExpertiseTypes.vue";
import CatalogRegions from "../views/CatalogRegions.vue";
import CatalogOrganization from "../views/CatalogOrganization.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/expertises",
    component: Expertises,
  },
  {
    path: "/expertise/:id",
    component: Expertise,
  },
  {
    path: "/admin",
    component: Admin,
  },
  {
    path: "/defects_catalog",
    component: CatalogDefects,
  },
  {
    path: "/surfaces_catalog",
    component: CatalogSurfaces,
  },
  {
    path: "/expertises_type_catalog",
    component: CatalogExpertiseTypes,
  },
  {
    path: "/regions_catalog",
    component: CatalogRegions,
  },
  {
    path: "/organization_catalog",
    component: CatalogOrganization,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
