<template>
  <v-chip
    v-if="isValidPriority"
    class="ma-2"
    :color="chipColor"
    :style="{ color: textColor }"
  >
    {{ priorityLabel }}
  </v-chip>
</template>

<script>
export default {
  name: 'PriorityChip',
  props: {
    priority: {
      type: String,
      required: true,
    },
  },
  computed: {
    isValidPriority() {
      return this.priority !== '' && this.priority !== null;
    },
    chipColor() {
      // Возвращает цвет чипса в зависимости от значения приоритета
      switch (this.priority) {
        case 'Срочный':
          return 'error'; // Красный
        case 'Высокий':
          return 'orange'; // Оранжевый
        case 'Обычный':
          return 'primary'; // Синий
        case 'Низкий':
          return 'default'; // Зеленый
        default:
          return 'default'; // Серый по умолчанию
      }
    },
    textColor() {
      // Устанавливает цвет текста: белый для всех цветов, кроме серого, который делает текст чёрным
      return this.chipColor === 'default' ? 'black' : 'white';
    },
    priorityLabel() {
      return this.priority || 'Приоритет не выбран';
    },
  },
};
</script>