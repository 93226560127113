<template>
  <v-app>
    <v-app-bar app color="primary" clippedLeft>
      <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer">
      </v-app-bar-nav-icon>
      <v-app-bar-title>
        <h3 class="text-h4 white--text">ЭПСЭ "Экспертиза"</h3>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <ButtonInfo :versionFront="this.$store.getters.getVersionUI" :versionAPI="this.$store.getters.getVersionApi" />
    </v-app-bar>
    <v-navigation-drawer clipped app left v-model="drawer" fixed>
      <v-list-item>
        <v-list-item-content>
          <v-img src="@/assets/logo2.png" max-height="100" contain></v-img>
          <v-list-item-title class="text-h6 text-center">
            ЭПСЭ "Экспертиза"
          </v-list-item-title>
          <v-list-item-subtitle class="d-none"> subtext </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <Sidebar />
    </v-navigation-drawer>


    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import ButtonInfo from "./components/AppBar/ButtonInfo.vue";

export default {
  name: "App",
  components: { Sidebar, ButtonInfo },
  data: () => ({
    drawer: true,
  }),
  mounted(){
    this.$store.dispatch("GET_API_VERSION");
  }
};
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.v-main {
  background: #f4f5fa;
}
</style>
