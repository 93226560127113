<template>
    <v-card>
        <v-dialog v-model="dialogEdit" width="500">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Редактирование</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogEdit = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-form v-model="isValidEditFrom" ref="editForm">
                        <v-text-field label="Код региона" required v-model="editedItem.code"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-text-field label="Наименование" required v-model="editedItem.name"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-checkbox hide-details name="requiredCause" v-model="editedItem.archive"
                            :label="`Архивый пункт`"></v-checkbox>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogEdit = false">
                        Отмена
                    </v-btn>
                    <v-btn color="primary" text @click="updateExpertiseType">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card-title>
            Справочник регионов
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col class="flex-grow-1 flex-shrink-0">
                    <v-form v-model="isValidForm" ref="newForm">
                        <v-row>
                            <v-col>
                                <v-text-field label="Код региона" required v-model="regionCode"
                                    :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field label="Наименование" required v-model="regionName"
                                    :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col class="col-auto">
                    <v-btn color="primary" @click="submitNewSurface">
                        Добавить
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr v-if="regions.length == 0">
                                    <td>Справочник пуст</td>
                                </tr>
                                <tr v-for="(item, i) in regions" :key="item.id">
                                    <td>{{ item.code }}</td>
                                    <td>{{ item.name }}</td>
                                    <td style="width: 50px;">
                                        <v-btn icon @click="openEditDialog(item, i)">
                                            <v-icon>mdi-file-edit-outline</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { catalogAPI } from "../../api/catalogsApi";
export default {
    components: {
    },
    data: () => ({
        regions: [],
        regionName: '',
        regionCode: '',
        isValidForm: true,
        isValidEditFrom: true,
        dialogEdit: false,
        editedItem: {
            id: null,
            number: null,
            archive: false,
            code: '',
            name: ''
        },
        editedItemIndex: -1
    }),
    methods: {
        async submitNewSurface() {
            this.$refs.newForm.validate();
            if (!this.isValidForm) return;
            try {
                // let formData = new FormData();
                // formData.append('name',this.surfaceName)
                let json = { name: this.regionName, code: this.regionCode };
                let response = await catalogAPI.addNewRegion(json);
                if (response.ok) {
                    let data = await response.json();
                    this.regionCode = '';
                    this.regionName = '';
                    this.regions.push(data);
                    this.$emit('msg:success', "Операция выполнена успешно");
                    this.$refs.newForm.resetValidation()
                }
            } catch (e) {
                this.$emit('msg:error', e.message);
                console.log('error', e)
            }
        },
        openEditDialog(item, i) {
            this.editedItem = { ...item };
            this.editedItemIndex = i
            this.dialogEdit = true;
        },
        async updateExpertiseType() {
            this.$refs.editForm.validate();
            if (!this.isValidEditFrom) return;
            this.dialogEdit = false;
            try {
                let response = await catalogAPI.updateRegionItem(this.editedItem)
                if (!response.ok) {
                    let text = await response.text();
                    this.$emit('msg:error', text);
                    console.warn(text);
                    return;
                }
                let data = await response.json();
                console.log(data);
                Object.assign(this.regions[this.editedItemIndex], data)
                this.$emit('msg:success', 'Запись успешно обновлена');
            } catch (e) {
                this.$emit('msg:error', e.message);
            }

        },
        async fetchCatalog() {
            try {
                let response = await catalogAPI.getRegionsCatalog();
                if (!response.ok) {
                    let textError = await response.text();
                    this.$emit('show:error', textError);
                    console.log(textError);
                    return;
                }
                let data = await response.json();
                this.regions = data.regionGuideList;
            } catch (e) {
                this.$emit('show:error', e.message);
                console.log(e.message);
            }
        },
    },
    mounted() {
        this.fetchCatalog();
    }
}
</script>