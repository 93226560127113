<template>
    <v-dialog
      v-model="dialog"
      width="500"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Справочник поверхностей</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <v-form ref="formSurface" v-model="isValid">
                <v-text-field
                  v-model="surfaceName"
                  label="Наименование"
                  :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                ></v-text-field>
                <v-checkbox
                hide-details
                name="requiredCause"
                v-model="archive"
                :label="`Архивый пункт`"
                ></v-checkbox>
              </v-form>

            </v-list-item-content>
          </v-list-item>
        </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="editSurface"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { catalogAPI } from "../../api/catalogsApi";
  export default {
    props:{
      surfaceID:{
        required:true
      }
    },
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        isValid: true,
        surfaceName:'',
        archive: false,
        number: -1
      }
    },
    methods:{
      async editSurface(){
        this.$refs.formSurface.validate();
        // let fd = {};
        // fd['name'] = this.surf;
        // fd['id'] = this.id;
        let fd = new FormData();
        fd.append('id',this.surfaceID);
        fd.append('name', this.surfaceName);
        fd.append('archive',this.archive);
        fd.append('number',this.number);
        let res = await catalogAPI.updateSurface(Object.fromEntries(fd));
        if(res.ok){
          this.$store.dispatch('fetchDefectSurfaces');
          this.dialog = false;
        } else {
          alert('Ощибка');
        }
      }
    },
    async mounted(){
      let res = await catalogAPI.getSurfaceByID(this.surfaceID);
      if(res.ok){
        let name = await res.json();
        this.surfaceName = name.surfacesGuide[0].name;
        this.archive = name.surfacesGuide[0].archive;
        this.number = name.surfacesGuide[0].number;
        console.log(name.surfacesGuide[0])
      } else {
        alert('Ошибка')
      }
    }
  }
</script>