<template>
    <div class="home">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <DefectsCatalog class="mt-4" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import DefectsCatalog from '../components/Catalogs/DefectsCatalog';
export default {
    name: "CatalogDefects",
    components: {
        DefectsCatalog
    },
};
</script>
