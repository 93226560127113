<template>
    <div>
        <v-dialog v-model="dialogEdit" width="500">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Редактирование</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogEdit = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-form v-model="isValidEditFrom" ref="editForm">
                        <v-text-field label="Наименование" required v-model="editedItem.name"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-text-field label="ИНН" required v-model="editedItem.inn"
                            :rules="[(v) => v.length > 0 || 'Обязательное поле']">
                        </v-text-field>
                        <v-checkbox hide-details name="requiredCause" v-model="editedItem.archive"
                            :label="`Архивый пункт`"></v-checkbox>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogEdit = false">
                        Отмена
                    </v-btn>
                    <v-btn color="primary" text @click="updateOrganizationItem">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row>
            <v-col cols="6">
                <v-card>
                    <v-card-title>Справочник организаций</v-card-title>
                    <v-card-text>
                        <v-sheet
                        outlined
                        class="pa-4"
                        rounded=""
                        >
                            <v-row>
                                <v-col class="flex-grow-1 flex-shrink-0">
                                    <v-form v-model="isValidForm" ref="newForm">
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                outlined
                                                dense
                                                label="Наименование"
                                                required
                                                v-model="name"
                                                :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                outlined
                                                dense
                                                label="ИНН"
                                                required
                                                v-model="inn"
                                                :counter="10"
                                                :rules="[(v) => !!v || 'Обязательное поле', v => v.length == 10 || 'ИНН д.б 10 символов',]"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-col>
                                <v-col class="col-auto">
                                    <v-btn
                                    color="primary"
                                    @click="submitOrganization"
                                    >
                                    Добавить
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-sheet>

                        <v-data-table
                            class="mt-4"
                            v-model="selected"
                            :headers="headers"
                            :items="organizations"
                            :single-select="true"
                            item-key="id"

                            :disable-pagination="true"
                            hide-default-footer
                            @click:row="clickRow"
                        >
                        <template v-slot:item.actions="{ item, index }">
                        <v-icon
                            small
                            class="mr-2"
                            @click="editOrganizationItem(item, index)"
                        >
                            mdi-pencil
                        </v-icon>
                        </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6">
                <OrganizationRegion
                    :organizations="organizations"
                    @msg:success="emitSucces"
                    @msg:error="emitError"
                />
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { catalogAPI } from '../../api/catalogsApi';
import OrganizationRegion from './OrganizationRegion';
    export default {
        components: {
            OrganizationRegion,
        },
        data() {
            return {
                dialogEdit:false,
                selected:[],
                headers:[
                    {text:'Наименование', value:'name' },
                    { text: 'ИНН', value: 'inn' },
                    { text: '', value: 'actions', sortable: false, align: 'end', },
                ],
                organizations: [],
                isValidForm:true,
                isValidEditFrom:true,
                name:'',
                inn:'',
                editedItem:{
                    id:null,
                    number:1,
                    name:'',
                    inn:'',
                    archive:false
                },
                editedItemIndex:-1,
                selectedOrganization:-1,
                selectedOrgName:'',
            }
        },
        methods: {
            emitError(txt){
                this.$emit('msg:error', txt);
            },
            emitSucces(txt){
                this.$emit('msg:success',txt)
            },
            async fetchCatalogOrganization() {
                try {
                    let response = await catalogAPI.getOrganizationCatalog();
                    if (!response.ok) {
                        let data = await response.json();
                        this.$emit('msg:error', data.message);
                        return;
                    }
                    let data = await response.json();
                    this.organizations = data.orgGuideList;
                } catch (e) {
                    this.$emit('msg:error', e.message);
                }
            },
            async submitOrganization() {
                this.$refs.newForm.validate();
                if(!this.isValidForm){
                    this.$emit('msg:error','Форма заполнена не до конца');
                    return;
                }
                try{
                    let result = await catalogAPI.addNewOrganization({ name: this.name, inn: this.inn });
                    if(!result.ok){
                        let error = await result.text();
                        this.$emit('msg:error',error);
                        return;
                    }
                    let obj = await result.json();
                    this.organizations.push(obj);
                }catch(e){
                    this.$emit('msg:error',e.message);
                }
            },
            async updateOrganizationItem(){
                this.$refs.editForm.validate();
                if(!this.isValidEditFrom) return;
                try {
                    let response = await catalogAPI.updateOrganizationItem(this.editedItem);
                    if(!response.ok){
                        if(response.status == 400){
                            let error = await response.json();
                            this.$emit('msg:error', error.message);
                        } else{
                            this.$emit('msg:error',await response.text());
                        }
                        this.dialogEdit = false;
                        return;
                    }
                    let originItem = this.organizations[this.editedItemIndex];
                    originItem = Object.assign(originItem,this.editedItem);
                    console.log(originItem)
                    this.$emit('msg:success','Изменения сохранены');
                    this.dialogEdit = false;
                } catch (e) {
                    this.$emit('msg:error','Что-то пошло не так');
                    console.warn(e.message);
                }
            },
            editOrganizationItem(item,i){
                console.log(item,i);
                this.editedItemIndex = i;
                this.editedItem = Object.assign({},item);
                this.dialogEdit = true;
            },
            clickRow(item,row,pe){
                if(pe.target.closest('.text-end')) return;
                if(!row.isSelected){
                    this.selectedOrganization = item.id;
                    this.selectedOrgName = item.name;
                }
                else this.selectedOrganization = -1;
                console.log(this.selectedOrganization)
                row.select(!row.isSelected)
            }
        },
        mounted () {
            this.fetchCatalogOrganization();
        },
    }
</script>

