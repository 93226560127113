<template>
    <div>
        <v-dialog v-model="dialogEdit" width="500">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Редактирование</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogEdit = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-form ref="editForm" class="mt-4">
                        <v-autocomplete
                            v-model="editedItem.idRegionGuide"
                            :items="regions"
                            item-text="name"
                            item-value="id"
                            label="Регион"
                            outlined
                            dense
                            hide-details
                            placeholder="Выберите регион"
                            persistent-placeholder
                        ></v-autocomplete>
                        <v-checkbox hide-details name="requiredCause" v-model="editedItem.archive"
                            :label="`Архивый пункт`"></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialogEdit = false">
                        Отмена
                    </v-btn>
                    <v-btn color="primary" text @click="updateRegion">
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-card>
            <v-card-title>Регионы организаций</v-card-title>
            <v-card-text>
        <div>
            <v-sheet
            outlined
            class="pa-4 mb-4"
            rounded=""
            >
                <v-autocomplete
                    v-model="selectedOrgID"
                    :items="organizations"
                    item-text="name"
                    item-value="id"
                    label="Организация"
                    outlined
                    dense
                    hide-details
                    placeholder="Выберите организацию"
                    persistent-placeholder
                    @change="onSelectOrg"
                ></v-autocomplete>
            </v-sheet>
            <v-sheet
            outlined
            class="pa-4"
            rounded=""
            >
                <v-row>
                    <v-col class="flex-grow-1 flex-shrink-0">
                        <v-form v-model="isValidForm" ref="newForm">

                            <v-autocomplete
                                v-model="selectedItem"
                                :items="regions"
                                item-text="name"
                                item-value="id"
                                label="Регион"
                                outlined
                                dense
                                hide-details
                                placeholder="Выберите регион"
                                persistent-placeholder
                            ></v-autocomplete>
                        </v-form>
                    </v-col>
                    <v-col class="col-auto">
                        <v-btn
                        color="primary"
                        @click="addNewRegion"
                        >
                        Добавить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-sheet>
            <div class="text-body-1 font-weight-light mt-4 text--primary">Текущие регионы организации</div>
                <v-alert
                icon="mdi-information-outline"
                text
                border="left"
                type="info"
                class="mt-4"
                v-if="!selectedOrgID"
                >
                Чтобы добавить/просмотреть регионы для организации, выберите ее из списка
                </v-alert>
                <v-sheet
                v-if="orgRegions.length==0 && selectedOrgID"
                outlined
                class="pa-4 mt-4"
                rounded=""
                style="background: #f5f5f5"
                >
                <div class="text-center text--disabled text-body-1">К организации не привязан ни один регион</div>
                </v-sheet>
                <v-data-table
                    v-if="orgRegions.length && selectedOrgID"
                    class="mt-4"
                    :headers="headers"
                    :items="orgRegions"
                    item-key="id"
                    :disable-pagination="true"
                    hide-default-footer
                >
                    <template v-slot:item.name="{ item }">
                        {{ getNameRegionByID(item.idRegionGuide) }}
                    </template>
                    <template v-slot:item.actions="{ item, index }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editRegionItem(item, index)"
                    >
                        mdi-pencil
                    </v-icon>
                    </template>
                </v-data-table>
        </div>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import { catalogAPI } from '../../api/catalogsApi';
    export default {
        props: {
            organizations:{
                type: Array
            }
        },
        data() {
            return {
                selectedOrgID:null,
                dialogEdit:false,
                isValidForm:true,
                regions: [],
                orgRegions:[],
                selectedItem:null,
                headers:[
                    { text: 'Регион', value: 'name' },
                    { text: '', value: 'actions', sortable: false, align: 'end', },
                ],
                editedItemIndex:-1,
                editedItemDefault: {
                    id: null,
                    number: 0,
                    archive: false,
                    idOrgGuide: 0,
                    idRegionGuide: 0
                },
                editedItem: {
                    id: null,
                    number: 0,
                    archive: false,
                    idOrgGuide: 0,
                    idRegionGuide: 0
                }
            }
        },
        methods: {
            async fetchRegions(id) {
                try{
                    let response  = await catalogAPI.getOrganizationRegion(id);
                    if(!response.ok){
                        if(response.status==400){
                            let error = await response.json();
                            this.$emit('msg:error',error.message);
                            return;
                        }
                        let text = await response.text();
                        this.$emit('msg:error', text);
                        return;
                    }
                    let json = await response.json();
                    this.orgRegions = json.orgRegGuide2List;
                    console.log('json',json);
                } catch(e){
                    console.warn(e.message);
                    this.$emit('msg:error', 'Что то пошло не так. Не удалось получить список регионов организации');
                }
            },
            async fetchRegionsCatalog(){
                try {
                    let response = await catalogAPI.getRegionsCatalog();
                    if (!response.ok) {
                        if (response.status == 400) {
                            let error = await response.json();
                            this.$emit('msg:error', error.message);
                            return;
                        }
                        this.$emit('msg:error', 'Не удалось получить список регионов');
                        return;
                    }
                    let json = await response.json();
                    this.regions = json.regionGuideList;
                } catch (e) {
                    console.warn(e.message);
                    this.$emit('msg:error', 'Не удалось выполнить запрос');
                }
            },
            async addNewRegion(){
                if(!this.selectedOrgID){
                    this.$emit('msg:error','Необходимо выбрать организацию');
                    return;
                }
                if(!this.selectedItem){
                    this.$emit('msg:error', 'Необходимо выбрать регион');
                    return;
                }
                let find = this.orgRegions.find(item=>item.idRegionGuide ==this.selectedItem);
                if(find){
                    this.$emit('msg:error','Данный регион уже добавлен к организации');
                    return;
                }
                try {
                    let item = {
                        "idOrgGuide": this.selectedOrgID,
                        "idRegionGuide": this.selectedItem
                    }
                    let res = await catalogAPI.addRegionForOrganization(item);
                    if (!res.ok) {
                        if (res.status == 400) {
                            let error = await res.json();
                            this.$emit('msg:error', error.message);
                            return;
                        }
                        this.$emit('msg:error', 'Не удалось добавить регион к организации');
                        return;
                    }
                    let json = await res.json();
                    this.orgRegions.push(json);
                    this.$emit('msg:success','Регион успешно добавлен к организации');
                } catch (e) {
                    console.warn(e.message);
                    this.$emit('msg:error', 'Что-то пошло не так!');
                }
            },
            editRegionItem(item,i){
                this.dialogEdit = true;
                this.editedItem = Object.assign(this.editedItemDefault,item);
                this.editedItemIndex = i;
            },
            async updateRegion(){
                try {
                    let response = await catalogAPI.updateOrganizationRegion(this.editedItem);
                    if(!response.ok){
                        let error;
                        if(response.status == 400){
                            error = await response.json();
                        } else {
                            error = await response.text();
                        }
                        this.$emit('msg:error', error.message);
                        this.dialogEdit = false;
                        return;
                    }
                    let newItem = await response.json();
                    this.orgRegions[this.editedItemIndex] = Object.assign(this.orgRegions[this.editedItemIndex],newItem);
                    this.$emit('msg:success','Изменения сохранены');
                } catch (e) {
                    console.warn(e.message);
                    this.$emit('msg:error','Что-то пошло не так');
                }finally{
                    this.dialogEdit = false;
                }
            },
            getNameRegionByID(id){
                let find = this.regions.find(item=>item.id==id);
                if(find) return find.name;
                return 'Не удалось найти имя';
            },
            onSelectOrg(){
                if(this.selectedOrgID){
                    this.fetchRegions(this.selectedOrgID);
                } else {
                    this.orgRegions = [];
                }
                console.log(this.selectedOrgID)
            }
        },
        created () {
            this.fetchRegionsCatalog();
        },
    }
</script>