<template>
  <div>
    <h1>Журнал сообщений</h1>
    <v-row class="mb-2 mt-2">
      <v-col>
        <v-btn @click="fetchLogs" text color="primary">
          <v-icon
            dark
            left
          >
            mdi-refresh
          </v-icon> Обновить</v-btn>
        </v-col>
      <v-spacer></v-spacer>
      <v-col class="col-auto">
        <v-btn @click="dialog=true" text color="red">
          <v-icon
            dark
            left
          >
          mdi-broom
          </v-icon>Очистить</v-btn>
      </v-col>
    </v-row>
    <v-data-table
            :headers="headers"
            :items="logs"
            class="elevation-1"
          >
          <template v-slot:item.dateTime="{ item }">
            {{
              getDataFormat(item.dateTime)
            }}
    </template>
        </v-data-table>
        <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Подтверждение
        </v-card-title>

        <v-card-text>
          Очистить журнал сообщений?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="dialog = false"
          >
            Нет
          </v-btn>

          <v-btn
            color="primary darken-1"
            text
            @click="clearLogs"
          >
            Да
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getLogs, removeLogs } from '../api/expertiseApi';

export default {
  props: {
    title: {
      type: String,
      default: 'Логи',
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: 'Журнал сообщений',
    },
  },
  data() {
    return {
      dialog: false,
      logs: [],

      headers:
      [
        { text: 'Дата', value: 'dateTime', width:"170px"},
        { text: 'API', value: 'uri' },
        { text: 'Тип', value: 'typeMessage' },
        { text: 'Сообщение', value: 'message' },
      ],
    };
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    async fetchLogs() {
      try {
        const response = await getLogs();
        if(response.ok){
          const dataLogs = await response.json()
          this.logs = dataLogs.logs.reverse()
        }
      } catch (error) {
        console.error('Ошибка при загрузке логов:', error);
      }
    },
    async clearLogs(){
      try{
        const response = await removeLogs();
        if(response.ok) this.logs = [];
      } catch(e){
        console.error(e)
      }
      this.dialog = false;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    getDataFormat(dt){
      let dateTime = new Date(dt)
      return dateTime.toLocaleString("ru-Ru")
    }
  },
};
</script>

