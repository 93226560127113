<template>
    <div>
        <v-btn
          class="ma-2"
          outlined
          rounded
          :color="(item.id == activeObject)?'white':'indigo'"
          :class="{primary: item.id == activeObject}"
          @click="clickDefect(item.id)"
          v-for="(item, i) in this.$store.getters.getDefectsObject"
          :key="i"
        >
          {{ item.name }} {{ item.numberType==0?'': item.numberType }}
        </v-btn>
    </div>
</template>

<script>
export default {
    name: "DefectsObjectList",
    props: {
        idExpertise: {
            required: true,
        },
    },
    data:()=>({
        activeObject:null
    }),
    methods:{
        clickDefect(id){
            this.$store.dispatch("fetchDefectsObjectList", id);
            this.$store.dispatch("setDefectObjCurrentId",id);
            this.activeObject = id;
        }
    },
    mounted() {
        this.$store.dispatch("fetchDefects", this.idExpertise);
    },
    beforeDestroy(){
        this.$store.dispatch("setDefectObjCurrentId", null);
        this.$store.commit('setDefetsObjectList',[]);
    }
}
</script>