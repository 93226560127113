<template>
    <div>
        <v-card>
            <v-card-title>
                Справочник недостатков поверхностей
            </v-card-title>
            <v-card-text>
                <v-dialog v-model="dialogDefect" width="500" hide-overlay persistent transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on">
                            Добавить недостаток
                        </v-btn>
                    </template>
                    <v-card>
                        <v-toolbar dark color="primary">

                            <v-toolbar-title>{{ formDefectTitle }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="closeDefectDialog">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="pt-4">
                                <v-form class="mt-4" v-model="isValidFormDefect" ref="formDefect" :lazy-validation="true">
                                    <v-select
                                        v-model="editedDefect.idSurface"
                                        name="idSurface"
                                        :items="surfaces"
                                        item-value="id"
                                        item-text="name"
                                        label="Объекты осмотра"
                                        outlined
                                        :rules="[(v) => v >= 0 || 'Обязательное поле']"
                                        dense
                                    />
                                    <v-textarea
                                    dense
                                    outlined
                                    name="defectName"
                                    label="Наименование недостатка"
                                    v-model="editedDefect.defectName"
                                    :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                                    ></v-textarea>
                                    <v-textarea
                                    dense
                                    :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                                    outlined
                                    name="standard"
                                    label="Стандарт"
                                    v-model="editedDefect.standard"
                                    ></v-textarea>
                                    <v-checkbox
                                    hide-details
                                    name="requiredValue"
                                    v-model="editedDefect.requiredValue"
                                    :label="`Вводить значение`"
                                    >
                                        <template v-slot:append>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon


                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    mdi-help-circle-outline
                                                    </v-icon>
                                                </template>
                                                <span v-pre>В тексте в месте для ввода значения ввести заполнитель в формате {{ЗНАЧЕНИЕ}}
                                                </span>
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>
                                    <v-checkbox
                                    hide-details
                                    name="requiredCause"
                                    v-model="editedDefect.requiredCause"
                                    :label="`Выбирать причину возникновения`"
                                    >
                                        <template v-slot:append>
                                            <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                mdi-help-circle-outline
                                                </v-icon>
                                            </template>
                                            <span v-pre>В тексте в месте где необходимо выбрать причину ввести заполнитель в формате {{ПРИЧИНА}}</span>
                                            </v-tooltip>
                                        </template>
                                    </v-checkbox>
                                </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="closeDefectDialog"
                            >
                                Отмена
                            </v-btn>
                            <v-btn color="primary" text @click="saveDefect">
                                Сохранить
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-text>
            <v-card-text>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Поверхность</th>
                            <th>Дефект</th>
                            <th>Стандарт</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="defects.length == 0">
                            <td colspan="5" class="text-center">Справочник пуст</td>
                        </tr>
                        <tr
                        v-for="(item) in defects"
                        :key="item.id"
                        >
                            <td>{{ item.surfaceName }}</td>
                            <td>
                                <template v-if="!item.requiredValue && !item.requiredCause">
                                    {{ item.defectName }}
                                </template>
                                <template v-else>
                                    <p v-html="getModyfiedDefectNameHtml(item.defectName)"></p>
                                </template>
                            </td>
                            <td>{{ item.standard }}</td>
                            <td>
                                <v-btn icon @click="editDefect(item)" >
                                    <v-icon>mdi-file-edit-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { catalogAPI } from '../../api/catalogsApi'
export default {
    components:{ },
    data:()=>({
        defectName:'',
        standard:'',
        idSurface:-1,
        defects:[],
        isValid:true,
        isValidFormDefect:true,
        editedDefect:{
            idSurface: -1,
            defectName: '',
            standard: '',
            id:-1,
            requiredValue:false,
            requiredCause:false,
            number:-1
        },
        editedDefectDefault:{
            idSurface: -1,
            defectName: '',
            standard: '',
            id: -1,
            requiredValue: false,
            requiredCause:false,
            number: -1
        },
        dialogDefect:false
    }),
    computed: {
        surfaces() {
            return this.$store.getters.getDefectSurfaces
        },
        formDefectTitle(){
            return this.editedDefect.id == -1?'Добавление недостатка':'Редактирование недостатка';
        }
    },
    methods: {
        getModyfiedDefectNameHtml(str){
            let result = str.replace('{{ЗНАЧЕНИЕ}}', '<span class="warning">{{ЗНАЧЕНИЕ}}</span>');
            return result.replace('{{ПРИЧИНА}}', '<span class="warning">{{ПРИЧИНА}}</span>');;
        },
        editDefect(item){
            console.log(item)
            this.editedDefect = Object.assign({}, item);
            this.dialogDefect = true;
        },
        closeDefectDialog(){
            this.dialogDefect = false;
            this.$nextTick(() => {
                this.editedDefect = Object.assign({}, this.editedDefectDefault);
                this.$refs.formDefect.resetValidation();
            })
        },
        async saveDefect(){
            this.$refs.formDefect.validate();
            if (!this.isValid) return;
            if(this.editedDefect.id==-1){
                let fd = new FormData(this.$refs.formDefect.$el);
                try {
                    let response = await catalogAPI.createDefectItem(Object.fromEntries(fd));
                    if (response.ok) {
                        let result = await response.json();
                        this.defects.push(result);
                        this.closeDefectDialog();
                    }
                } catch (e) {
                    this.closeDefectDialog();
                    console.log(e);
                }
            } else {
                let newData = {
                    "id": this.editedDefect.id,
                    "archive": false,
                    "idSurface": this.editedDefect.idSurface,
                    "defectName": this.editedDefect.defectName,
                    "standard": this.editedDefect.standard,
                    "requiredValue": this.editedDefect.requiredValue,
                    "requiredCause": this.editedDefect.requiredCause,
                    "number": this.editedDefect.number
                }
                try{
                    let res = await catalogAPI.updateDefectsForSurface(newData);
                    if (res.ok) {

                        // let resJSON = await res.json();
                        this.updateCatalogDefects(newData);
                    } else {
                        alert(await res.text())
                    }
                }catch(e){
                    alert(e.message);
                }finally{
                    this.closeDefectDialog()
                }
            }
        },
        async getDefects() {
            try {
                let response = await catalogAPI.getDefectsCatalog();
                if (response.ok) {
                    let json = await response.json();
                    this.defects = json.defectsGuide;
                }
            } catch (e) {
                console.log(e);
            }
        },
        updateCatalogDefects(item){
            let res = this.defects.find(function(itemArr){ return itemArr.id == item.id });
            if(res){
                res.standard = item.standard;
                res.defectName = item.defectName;
                res.idSurface = item.idSurface;
                res.requiredValue = item.requiredValue;
                res.requiredCause = item.requiredCause;
                if(item['number']) res.number = item.number
                if(item['surfaceName']) res.surfaceName = item.surfaceName;
            }
            // this.getDefects();
        }
    },
    created(){
        this.getDefects();
    },
    mounted() {
        this.$store.dispatch('fetchDefectSurfaces');
    },

}
</script>