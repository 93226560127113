<template>
        <v-chip
          v-if="isValidDays"
          class="ma-2"
          :color="chipColor"
          outlined
        >
        Осталось дней: {{ days }}
        </v-chip>
</template>

<script>
export default {
  name: 'DaysLeft',
  props: {
    // Пропс для передачи количества дней
    days: {
      type: [Number, String], // Принимает число или строку
      default: 5, // Значение по умолчанию
    },
  },
  computed: {
    isValidDays() {
      // Проверяем, чтобы days был числом и не пустой строкой
      return this.days !== '' && this.days !== null && !isNaN(this.days);
    },
    chipColor() {
      // Возвращает цвет чипса в зависимости от количества дней
      if (this.days <= 3) {
        return 'red'; // Красный цвет для 0-3 дней
      } else if (this.days >= 4 && this.days <= 6) {
        return 'orange'; // Оранжевый цвет для 4-6 дней
      } else if (this.days >= 7) {
        return 'black'; // Черный цвет для 7 и более дней
      } else {
        return 'grey'; // Серый цвет, если значение не соответствует условиям
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
</style>