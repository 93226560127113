<template>
  <div>
      <v-dialog
        v-model="dialogConfirm"
        persistent
        max-width="450"
      >
        <v-card>
          <v-card-title class="text-h5">
            Подтверждение
          </v-card-title>
          <v-card-text>Удалить дефект помещения?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              text
              @click="dialogConfirm = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="confirmRemove"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card outlined>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                  <th>№</th>
                  <th>Поверхность</th>
                  <th>Недостаток</th>
                  <th>Норматив</th>
                  <th>Фото</th>
                  <th></th>
                  <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="defects.length == 0">
                  <td colspan="6" class="text-center">Нет ни одного добавленного недостатка</td>
              </tr>
              <tr
                v-for="(item, index) in defects"
                :key="item.id"
              >
                <td>{{ index + 1 }}</td>
                  <td>{{ item.defectGuide.surfaceName }}</td>
                  <td>
                    <template v-if="!item.defectGuide.requiredValue && !item.defectGuide.requiredCause">
                        {{ item.defectGuide.defectName }}
                    </template>
                    <template v-else>
                        <p v-html="getModifiedDefectNameHtml(item)"></p>
                    </template>
                  </td>
                  <td>{{ item.defectGuide.standard }}</td>
                <td>
                  <div class="d-flex justify-center" style="gap:10px">
                    <v-img v-if="item.photosDefect1"
                      max-width="150"
                      :src="item.photosDefect1.photoPath"
                    ></v-img>
                      <v-img v-if="item.photosDefect2"
                        max-width="150"
                        :src="item.photosDefect2.photoPath"
                      ></v-img>
                  </div>
                </td>
                  <td style="width: 40px;" class="pa-0">
                    <v-btn
                      icon
                      depressed
                      @click="openEditForm(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                <td style="width: 70px;">
                  <v-btn
                    icon
                    depressed
                    @click="openConfirmRemove(item.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
  </div>


</template>

<script>
import { removeDefectsFromIspObject } from '../../api/expertiseApi';
export default {
    data:()=>({
      removedID:-1,
      dialogConfirm: false
    }),
    computed:{
        defects(){
            return this.$store.getters.defectsObjectList;
        }
    },
    methods:{
      openEditForm(item){
        console.log(item,'clicedit')
        this.$emit('click:edit',item);
      },
      openConfirmRemove(id){
        this.removedID = id;
        this.dialogConfirm = true;
      },
      getModifiedDefectNameHtml(itemDefect){
        let replacedString = '<span class="warning">___</span>';
        if(itemDefect.value){
          replacedString = `<span class="blue lighten-3">${itemDefect.value}</span>`;
        }
        let res = itemDefect.defectGuide.defectName;
        if (itemDefect.cause) {
          res = res.replace('{{ПРИЧИНА}}', `<span class="blue lighten-3">${itemDefect.cause}</span>`);
        } else {
          res = res.replace('{{ПРИЧИНА}}', '<span class="warning">___</span>');
        }
        return res.replace('{{ЗНАЧЕНИЕ}}',replacedString);
      },
      async confirmRemove(){
        try{
          let response = await removeDefectsFromIspObject(this.removedID);
          if (response.ok) {
            let data = await response.text();
            console.log(data);
            this.$store.dispatch('removeDefectInspObj', this.removedID)
          } else {
            alert('Не удалось выполнить запрос')
          }
        }catch(e){
          alert(e.message);
        }finally{
          this.removedID = -1;
          this.dialogConfirm = false;
        }
      }
    }
}
</script>