<template>
  <div class="home">
    <v-container>
      <h1>Главная</h1>
      <div class="subtitle-1">Общая статистика и информация по ресурсу</div>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>
