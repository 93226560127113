<template>
    <v-card>
        <v-card-title>
            Справочник поверхностей
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col class="flex-grow-1 flex-shrink-0">
                    <v-form v-model="isValidSurfaceForm" ref="newSurfaceForm">
                        <v-text-field
                        label="Наименование поверхности"
                        required
                        v-model="surfaceName"
                        :rules="[(v) => v.length > 0 || 'Обязательное поле']"
                        >
                        </v-text-field>
                    </v-form>
                </v-col>
                <v-col class="col-auto">
                    <v-btn
                    color="primary"
                    @click="submitNewSurface"
                    >
                    Добавить
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr
                                v-for="item in surfaces"
                                :key="item.id"
                                >
                                <td>{{ item.name }}</td>
                                <td style="width: 50px;"><DefectEditDialog :surfaceID="item.id"/></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { addNewDefectSurface } from '../../api/expertiseApi'
import DefectEditDialog from '../Catalogs/DefectEditDialog.vue'
export default {
    components:{
        DefectEditDialog: DefectEditDialog
    },
    data:()=>({
        surfaceName:'',
        isValidSurfaceForm:true
    }),
    methods:{
        async submitNewSurface() {
            this.$refs.newSurfaceForm.validate();
            if (!this.isValidSurfaceForm) return;
            try {
                // let formData = new FormData();
                // formData.append('name',this.surfaceName)
                let json = {name:this.surfaceName, archive : false,number:-1};
                let response = await addNewDefectSurface(JSON.stringify(json));
                if (response.ok) {
                    let data = await response.json();
                    this.$store.commit("addDefectSurfaces", data);
                    this.surfaceName = '';
                    this.$refs.newSurfaceForm.resetValidation()
                }
            } catch(e) {
                console.log('error',e)
            }
        },
    },
    mounted(){
        this.$store.dispatch('fetchDefectSurfaces');
    },
    computed:{
        surfaces(){
            return this.$store.getters.getDefectSurfaces
        }
    }

}
</script>