import Vue from "vue";
import Vuex from "vuex";

import expertisesModule from "@/store/expertisesModule";
import { getVersionApi } from "../api/expertiseApi";
import { version } from "../../package";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    expertisesModule,
  },
  state:{
    apiVersion: null,
    uiVersion: version
  },
  getters:{
    getVersionUI: function(state){
      return state.uiVersion;
    },
    getVersionApi: function(state){
      return state.apiVersion
    }
  },
  mutations:{
    CHANGE_API_VERSION(state,value){
      state.apiVersion = value;
    }
  },
  actions:{
    GET_API_VERSION({commit}){
      // if(this.state.apiVersion !=null) return;
      getVersionApi()
      .then((result)=>result.text())
      .then((version)=>{
        commit("CHANGE_API_VERSION",version);
      })
      .catch(()=>{
        commit("CHANGE_API_VERSION", "???");
      })
    }
  }
});
