<template>
  <v-list dense expand>
    <v-list-item-group mandatory no-action>
      <v-list-item to="/" link color="primary" v-show="false">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Главная</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item to="/expertises" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-file-sign</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Экспертизы</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item to="/courts" link color="primary">
        <v-list-item-icon>
          <v-icon>mdi-list-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>СУДЫ</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

    </v-list-item-group>
    <v-list-group
      v-show="false"
      :value="true"
      prepend-icon="mdi-file-sign"
      no-action
      mandatory
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>Экспертизы</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item
        v-for="(item, i) in expertise"
        :key="i + item.text"
        link
        :to="item.href"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-group
      :value="true"
      no-action
      prepend-icon="mdi-list-box-outline"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title>Справочники</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="(item, i) in books" :key="i" link :to="item.href">
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
        <v-list-item to="/admin" link color="primary">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Администрирование</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "Sidebar",

  data: () => ({
    expertise: [
      {
        text: "Почерковедческая",
        href: "/expertises",
      },
      {
        text: "Автороведческая",
        href: "/expertises1",
      },
      {
        text: "Техническая",
        href: "/expertises2",
      },
      {
        text: "Взрывотехническая",
        href: "/expertises3",
      },
      {
        text: "Почвоведческая",
        href: "/expertises4",
      },
      {
        text: "Биологическая",
        href: "/expertises5",
      },
      {
        text: "Автотехническая",
        href: "/expertises6",
      },
      {
        text: "Пожарно-техническая",
        href: "/expertises7",
      },
      {
        text: "Взрывотехнологическая",
        href: "/expertises8",
      },
      {
        text: "Бухгалтерская",
        href: "/expertises9",
      },
      {
        text: "Товароведческая",
        href: "/expertises10",
      },
    ],
    books: [
      {
        text: "Виды экспертиз",
        href: "/expertises_type_catalog",
      },
      {
        text: "Поверхности",
        href: "/surfaces_catalog",
      },
      {
        text: "Недостатки",
        href: "/defects_catalog",
      },
      {
        text: "Суды",
        href: "/expertises11",
      },
      {
        text: "Организации",
        href: "/organization_catalog",
      },
      {
        text: "Регионы",
        href: "/regions_catalog",
      },
    ],
  }),
};
</script>
