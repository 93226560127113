<template>
    <v-row>
        <v-snackbar
          :timeout="3000"
          v-model="snackError"
          fixed
          bottom
          right
          color="error"
          elevation="24"
        >
          {{ errorText }}
        </v-snackbar>

        <v-col cols="12">
            <v-btn text color="primary" @click="backClick">
                <v-icon left>mdi-arrow-left</v-icon>
                Назад
            </v-btn>
            <v-btn color="primary" @click="addDefect">
                Сохранить
            </v-btn>
                <v-sheet
                rounded
                outlined
                class="pa-4 mt-4"
                >
                    <!-- <DefectsPhotoList/> -->
                    <div v-if="selectedFoto.length==0" class="text-center mb-4">Выберите не более 2-х фото</div>
                    <div class="d-flex" style="gap:10px">
                          <v-img v-for="(item,i) in selectedFoto"
                            :key="item.id"
                            max-width="150"
                            :src="item.path"
                          >
                            <v-btn

                            fab
                            small
                            class="float-end ma-1"
                            @click="removeSelectedImage(i)"
                            >
                            <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </v-img>
                    </div>

                </v-sheet>

        </v-col>
        <v-col class="col-auto">
            <v-card>
                <v-card-title>
                    Выберите поверхность
                </v-card-title>
                <v-card-text v-if="false">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <tr v-for="item in surfaces" :key="item.id" :class="{'blue':selectedSurface==item.id,'lighten-5': selectedSurface == item.id }"
                                    @click="onSelectSurface(item.id)"
                                >
                                    <td>{{ item.name }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-text>
                    <div v-for="(item) in surfacesGuide" :key="item.name">
                        <v-chip
                        @click="onSelectSurface(item.id)"
                        outlined
                        label
                        color="primary"
                        class="mt-2 d-block"
                        :input-value="selectedSurface == item.id"
                        filter
                        >
                            {{ item.name }}
                        </v-chip>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col>
            <v-card>
                <v-card-title>
                    Выберите недостаток
                </v-card-title>
                <v-card-text v-if="selectedSurface==null">
                    Для выбора недостатка необходимо выбрать поверхность
                </v-card-text>
                <v-card-text v-if="hasErrorDefectsForSurface">
                    {{ errorDefectsForSurfaceText }}
                </v-card-text>
                <v-card-text v-if="selectedSurface!=null" >
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Дефект</th>
                                <th>Значение</th>
                                <th>Стандарт</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="defects.length == 0">
                                <td colspan="5" class="text-center">Справочник пуст</td>
                            </tr>
                            <tr
                            v-for="(item) in defects"
                            :key="item.id"
                            @click="onSelecDefect(item)"
                            style="cursor: pointer;"
                            :class="{'blue': selectedDefectsID == item.id, 'lighten-5': selectedDefectsID == item.id }"
                            >
                            <td>
                                <template v-if="!item.requiredValue && !item.requiredCause">
                                    {{ item.defectName }}
                                </template>
                                <template v-else>
                                    <p v-html="getModifiedDefectNameHtml(item)"></p>
                                </template>
                            </td>
                            <td>
                                <v-text-field
                                v-if="item.requiredValue"
                                outlined
                                dense
                                v-model="item.value"
                                label="Значение"
                                hide-details="auto"
                                ></v-text-field>
                                <v-select class="mt-4" v-if="item.requiredCause"
                                v-model="item.cause"
                                :items="cause"
                                @change="selectChange(item)"
                                dense
                                label=""
                                solo
                                ></v-select>
                            </td>
                            <td>{{ item.standard }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>
import { getAllDefectsForSurface, createNewDefectForSurface, getAllDefectSurfaces, updateDefectForObject } from '../../api/expertiseApi'
// import DefectEditDialog from '../Catalogs/DefectEditDialog.vue'
export default {
    props:{
        'idExpertise':{
            required:true
        },
        'idInspObj':{
            required:true
        },
        'editedDefect':{
            required:false
        }
    },
    components: {
        // DefectEditDialog: DefectEditDialog
    },
    data: () => ({
        causeSelect: '',
        cause:[
            { text: 'Не установлено', value: '' },
            {text:"производство", value:"производство"},
            { text: "эксплуатация", value: "эксплуатация" },

        ],
        snackError:false,
        errorText:'',
        surfaceName: '',
        selectedSurface:null,
        errorDefectsForSurfaceText:'',
        hasErrorDefectsForSurface:false,
        defects:[],
        selectedDefectsID:null,
        selectedDefectItem:null,
        surfacesGuide:[]
    }),
    methods: {
        backClick(){
            this.$emit('onBackClick')
        },
        getModifiedDefectNameHtml(itemDefect) {
            console.log(itemDefect,'getModifiedDefectNameHtml')
            let res = itemDefect.defectName;
            let replacedString = '<span class="warning">___</span>';
            if (itemDefect.value) {
                replacedString = `<span class="blue lighten-3">${itemDefect.value}</span>`;
            }
            if(itemDefect.cause){
                res = res.replace('{{ПРИЧИНА}}', `<span class="blue lighten-3">${itemDefect.cause}</span>`);
            } else {
                res = res.replace('{{ПРИЧИНА}}', '<span class="warning">___</span>');
            }
            return res.replace('{{ЗНАЧЕНИЕ}}', replacedString);
        },
        selectChange(item){
            // item.cause = this.causeSelect;
            // item['case'] = this.causeSelect;
            //item['case'] = item.case;
            this.getModifiedDefectNameHtml(item)
            console.log(item, this.causeSelect)
        },
        async onSelectSurface(id){
            this.hasErrorDefectsForSurface = false;
            this.selectedDefectsID = null;
            this.selectedSurface = id;
            try{
                let response = await getAllDefectsForSurface(id);
                if (response.ok) {
                    let data = await response.json();
                    data.defectsGuide.forEach((item)=>item.value='')
                    data.defectsGuide.forEach((item) => item.cause = '')
                    this.defects = data.defectsGuide;
                    console.log('defects!!', data)
                    if(this.editedDefect){
                        console.log(this.editedDefect,'editedDefect')
                        let res = this.defects.find(item=>item.id== this.editedDefect.defectGuide.id);
                        if(this.editedDefect.photosDefect1)
                            this.$store.commit("addDefectPhotoToSelectedArray", {
                                id: this.editedDefect.photosDefect1.id, path: this.editedDefect.photosDefect1.photoPath
                            });
                        if (this.editedDefect.photosDefect2)
                            this.$store.commit("addDefectPhotoToSelectedArray", {
                                id: this.editedDefect.photosDefect2.id, path: this.editedDefect.photosDefect2.photoPath
                            });
                        this.onSelecDefect(res);
                    }
                } else {
                    this.hasErrorDefectsForSurface = true;
                    this.errorDefectsForSurfaceText = `Не удалось получить список недостатков для поверхности`;
                }
            }catch(e){
                this.hasErrorDefectsForSurface = true;
                this.errorDefectsForSurfaceText = e.message;
            }

        },
        onSelecDefect(item){
            if (this.editedDefect && item.id == this.selectedDefectsID)
                return;
            this.selectedDefectsID = item.id;
            this.selectedDefectItem = item;
            if(!(this.editedDefect && this.selectedDefectsID == this.editedDefect.defectGuide.id))
                return;
            let i = this.defects.findIndex(value => {
                return value.id == item.id
            });
            if (i < 0) return;
            if(this.editedDefect.requiredValue){
                this.defects[i]['value'] = this.editedDefect.value;
            }
            if(this.editedDefect.requiredCause){
                this.defects[i]['cause'] = this.editedDefect.cause;
                console.log('modife',this.defects[i],this.editedDefect)
            }
        },
        async addDefect(){

            if (this.selectedSurface == null) {
                this.snackError = true;
                this.errorText = 'Выберите поверхность';
                return;
            }
            if (this.selectedDefectsID == null) {
                this.snackError = true;
                this.errorText = 'Выберите недостаток';
                return;
            }
            let data = {
                idInspObj: this.idInspObj,
                idSurfaceGuide: this.selectedSurface,
                idDefectGuide: this.selectedDefectsID,
                idPhotoDefect1:null,
                idPhotoDefect2:null,
                value:this.selectedDefectItem.value,
                cause:this.selectedDefectItem.cause
            };
            if(this.selectedDefectItem.requiredCause){
                data.cause = this.selectedDefectItem.cause;
            }
            for(let i=0;i<this.selectedFoto.length;i++){
                data[`idPhotoDefect${i+1}`] = this.selectedFoto[i].id;
            }
            if (this.editedDefect) {
                this.updatedDefect(data);
                return;
            }
            try{
                let response = await createNewDefectForSurface(data);
                if (response.ok) {
                    await response.json();
                    this.$store.dispatch('fetchDefectsObjectList', this.idInspObj);
                    this.$emit('onaddsuccess','Недостаток добавлен')
                } else {
                    this.snackError = true;
                    this.errorText = 'Не удалось выполнить запрос';
                }
            }catch(e){
                this.snackError = true;
                this.errorText = e.message;
            }

        },
        async updatedDefect(itemData){
            itemData['id'] = this.editedDefect.id;
            try{
                let response = await updateDefectForObject(itemData);
                if(!response.ok){
                    this.snackError = true;
                    this.errorText = 'Не удалось выполнить запрос';
                    return;
                }
                let data = await response.json();
                this.$store.commit('updateDefectsObjectListItem',data);
                this.$emit('onaddsuccess', 'Недостаток изменен')
            }catch{
                this.snackError = true;
                this.errorText = 'Что-то пошло не так';
            }
        },
        removeSelectedImage(i){
            this.$store.commit('removeSelectedFoto',i);
        },
        async getSurfaces(){
            let response = await getAllDefectSurfaces();
            if(response.ok){
                let json = await response.json();
                console.log(json,"sgsdfsdfsdf");
                this.surfacesGuide = json.surfacesGuide;
                // surfacesGuide
                console.log('getSurfaces',json)
                this.$nextTick(() => {
                    if(this.editedDefect){
                        console.log('getSurfaces','onSelectDefect')
                        // this.onSelecDefect(this.editedDefect.surfaceGuide.id);
                    }

                })
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            if(this.editedDefect){
                console.log('mounted','onSelectSurface');
                this.onSelectSurface(this.editedDefect.surfaceGuide.id);
            }
        })


    },
    created(){
        this.getSurfaces()
        this.$store.dispatch("updateDefectSelectedFotos", []);
        this.$store.dispatch('fetchDefectSurfaces');
    },
    computed: {
        surfaces() {
            return this.$store.getters.getDefectSurfaces
        },
        selectedFoto(){
            return this.$store.getters.getDefectSelectedFotos
        }
    }

}
</script>