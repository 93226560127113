<template>
  <v-container fluid>
        <v-tabs v-model="tab" background-color="#f4f5fa">
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1"> Информация </v-tab>

          <v-tab href="#tab-2"> Осмотр </v-tab>

          <v-tab href="#tab-3" class="d-none"> Заключение </v-tab>
        </v-tabs>
    <v-card>


      <v-tabs-items v-model="tab">
        <v-tab-item id="tab-1">
          <v-card flat>
            <v-card-text>
              <common-form :id-expertise="this.$route.params.id" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="tab-2">
          <v-card flat>
            <v-card-text>
              <foto-object :id-expertise="this.$route.params.id" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="tab-3" class="d-none">
          <v-card flat>
            <v-card-text>Заключение</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import commonForm from "../components/ExpertiseEdit/commonFormEdit.vue";
import fotoObject from "../components/TableViews/fotoObject.vue";

export default {
  components: {
    commonForm,
    fotoObject,
  },
  data: () => ({
    tab: null,
  }),
  methods:{ },
  mounted(){
    this.$store.dispatch("fetchAllObjectsInspection");
  }
};
</script>

<style></style>
