<template>
    <div>
          <v-dialog
            v-model="dialogConfirmRemovePhoto"
            persistent
            max-width="450"
          >
            <v-card>
              <v-card-title class="text-h5">
                Подтверждение
              </v-card-title>
              <v-card-text class="d-flex flex-column align-center" v-if="removePhotoIsProcces">
                <v-progress-circular
                  indeterminate
                  color="red"
                ></v-progress-circular>
                <div class="mt-2 red--text">Удаление...</div>
              </v-card-text>
              <v-card-text v-if="!removePhotoIsProcces">Удалить фотографию недостатка?</v-card-text>
              <v-card-actions v-if="!removePhotoIsProcces">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="dialogConfirmRemovePhoto = false"
                >
                  Отмена
                </v-btn>
                <v-btn
                  color="red darken-1"
                  text
                  @click="removedPhotoDefectConfirmed"
                >
                  Удалить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-snackbar
            :timeout="3000"
            v-model="snackSuccess"
            fixed
            bottom
            right
            color="success"
            elevation="24"
        >
            {{ snackSuccessText }}
        </v-snackbar>
        <v-snackbar
            :timeout="3000"
            v-model="snackError"
            fixed
            bottom
            right
            color="error"
            elevation="24"
        >
            {{snackErrorText}}
        </v-snackbar>
        <v-snackbar
            :timeout="3000"
            v-model="snackWarning"
            fixed
            bottom
            right
            color="warning"
            elevation="24"
        >
            {{ snackWarningText }}
        </v-snackbar>
        <DefectsObjectList :id-expertise="this.$route.params.id"/>
        <v-divider></v-divider>
        <v-row class="mt-4 mb-4">
            <v-col>
                <v-btn @click="selectFileClick" color="primary">
                <v-icon left>
                    mdi-plus
                </v-icon>
                Добавить фотографию
                </v-btn>
            </v-col>
            <v-col class="text-right">
                <v-btn @click="openFullTable" color="primary">
                <v-icon left>
                    mdi-table-multiple
                </v-icon>
                Ведомость недостатков
                </v-btn>
            </v-col>
        </v-row>
            <v-sheet
            rounded
            outlined
            class="pt-4"
            >
                <DefectsPhotoList @remove-image="onConfirmRemovePhotoDefect" :modeAdd="fotoModeEdit"/>
            </v-sheet>

        <v-row class="mt-4" v-if="!fotoModeEdit">
            <v-col>
                    <v-btn color="primary" @click="showAddDefects">
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                    Добавить недостаток
                    </v-btn>
            </v-col>
        </v-row>
        <DefectCreate @onaddsuccess="addDefectSucces" @onBackClick="exitFromEditMode" class="mt-4" v-if="fotoModeEdit" :id-expertise="this.$route.params.id" :idInspObj="currentDefectObjId" :editedDefect="editedDefect" />
        <div v-if="!fotoModeEdit" class="text-h5 mt-4 mb-4">Недостатки</div>
        <DefectsTable v-if="!fotoModeEdit" @click:edit="showEditDefectForm" />
        <v-file-input
            ref="input"
            class="d-none"
            accept="image/*"
            dense
            outlined
            v-model="files"
            show-size
            counter
            multiple
            @change="reedFiles"
        />
        <v-dialog v-model="resultDialog"
        fullscreen
        transition="dialog-bottom-transition"
        scrollable
        >
            <v-card >
            <v-card-title class="ma-0 pa-0">
                  <v-toolbar
                    flat
                    dark
                    color="primary"
                  >
                    <v-toolbar-title>Ведомость недостатков</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                        dark
                        :loading="exportLoading"
                        text
                        @click="downloadTable"
                      >
                            <v-icon
                                left
                                dark
                            >
                                mdi-download
                            </v-icon>
                        Экспорт в word
                      </v-btn>
                        <v-btn
                            icon
                            dark
                            @click="resultDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
            </v-card-title>
              <v-card-text>
                <v-simple-table class="mt-4 border-table">
                    <template v-slot:default>
                    <thead>
                        <tr class="grey">
                        <th class="text-left" style="font-size: 16px;">
                            №
                        </th>
                        <th class="text-left" style="font-size: 16px;">
                            Недостаток выявленный в ходе экспертного осмотра
                        </th>
                        <th style="font-size: 16px;">Норматив/Пояснение</th>
                        <th style="font-size: 16px;">Фото</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="allDefects.length==0">
                            <td colspan="4" class="text-center">Недостатки отсутствуют</td>
                        </tr>
                        <!-- <tr
                        v-for="(item,i) in allDefects"
                        :key="item.name"
                        >
                        <td>{{ i+1 }}</td>
                        <td colspan="3" class="text-center">{{ item.name }}</td>
                        </tr> -->
                        <template v-for="(item, i) in allDefects">
                            <tr  :key="item.name+i" class="grey lighten-1">
                                <td>{{ i+1 }}</td>
                                <td colspan="3" class="text-center font-weight-bold">{{ item.name }}</td>
                            </tr>
                            <template v-for="(surface, j) in item.surfaces">
                                <tr  :key="surface.name+j+item.name" class="grey lighten-2">
                                    <td>{{i + 1}}.{{ j+1 }}</td>
                                    <td colspan="3" class="text-center">{{ surface.name }}</td>
                                </tr>
                                <template v-for="(child, k) in surface.defects" >
                                    <tr v-bind:key="child.id" >
                                        <td>{{ i + 1 }}.{{ j + 1 }}.{{ k + 1 }}</td>
                                        <td>
                                            <template v-if="!child.requiredValue && !child.requiredCause">
                                                {{ child.defectGuide.defectName }}
                                            </template>
                                            <template v-else>
                                                <p v-html="getModifiedDefectNameHtml(child)"></p>
                                            </template>
                                        </td>
                                        <td>{{ child.defectGuide.standard }}</td>
                                        <td>
                                            <div class="d-flex justify-center" style="gap:10px">
                                                <v-img v-if="child.photosDefect1"
                                                max-width="150"
                                                :src="child.photosDefect1.photoPath"
                                                ></v-img>
                                                <v-img v-if="child.photosDefect2"
                                                    max-width="150"
                                                    :src="child.photosDefect2.photoPath"
                                                ></v-img>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>

                        </template>
                    </tbody>
                    </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import DefectsObjectList from './DefectsObjectList';
import DefectsPhotoList from './DefectsPhotoList';
import DefectsTable from './DefectsTable';
import DefectCreate from './DefectCreate';
import { uploadFotoDefect, removePhotoDefectByID, getAllDefectsForExpertise, getDefectsTableDoc } from '../../api/expertiseApi'
export default {
    components:{
        DefectsObjectList,
        DefectsPhotoList,
        DefectsTable,
        DefectCreate
    },
    data: () => ({
        exportLoading:false,
        files: [],
        fotoModeEdit: false,
        idInspObj:null,
        snackSuccess:false,
        snackSuccessText:'',
        snackWarning:false,
        snackWarningText:'',
        dialogConfirmRemovePhoto: false,
        removedPhotoDefectID:null,
        removePhotoIsProcces:true,
        snackErrorText:'',
        snackError:false,
        editedDefect:null,
        resultDialog:false,
        allDefects:[]
    }),
    computed: {
        defects() {
            return this.$store.getters.defectsObjectList;
        },
        currentDefectObjId(){
            return this.$store.getters.defectObjCurrentID;
        }
    },
    methods:{
        async downloadTable(){
            this.exportLoading = true;
            let result = await getDefectsTableDoc(this.$route.params.id);
            if (result.ok) {
                let fileName = 'Документ.docx';
                const contentDisposition = result.headers.get('Content-Disposition');
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch.length === 2) {
                        fileName = fileNameMatch[1];
                    }
                }
                let blob = await result.blob();
                const file = new Blob([blob]);
                const fileUrl = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
                this.exportLoading = false;
            } else {
                if(result.status==412){
                    this.snackWarning = true;
                    this.snackWarningText = `Для формирования таблицы, нужно заполнить в дефекте 'Отклонения от заданного уклона покрытий (причина возникновения: )' значение или причину.`;
                } else {
                    this.snackError = true;
                    this.snackErrorText = 'Не удалось выполнить запрос';
                }
                this.exportLoading = false;
            }
        },
        async openFullTable(){
            this.resultDialog = true;
            let response = await getAllDefectsForExpertise(this.$route.params.id)
            if(!response.ok){
                this.snackError = true;
                this.snackErrorText = 'Не удалось выполнить запрос';
                return;
            }
            let json = await response.json();
            // this.allDefects = json.defects;
            this.allDefects = [];
            // console.log(json.defects);
            for(let i=0;i<json.defects.length;i++){
                let defect = json.defects[i];
                let nameSurface = defect.inspObj.name;
                if(defect.inspObj.numberType!=0) nameSurface+=` ${defect.inspObj.numberType}`;
                console.log(defect.inspObj.numberType)
                let item = this.findDefectByName(nameSurface);
                let currentDefect = this.findSurfaceByName(item,defect.surfaceGuide.name)
                currentDefect.defects.push(defect)
            }
            console.log(this.allDefects);
        },
        getModifiedDefectNameHtml(itemDefect) {
            // console.log(itemDefect.defectGuide.defectName,'modifed')
            let replacedString = '<span class="warning">___</span>';
            if (itemDefect.value) {
                replacedString = `<span class="blue lighten-3">!!!!${itemDefect.value}</span>`;
            }
            let res = itemDefect.defectGuide.defectName;
            if (itemDefect.cause) {
                res = res.replace('{{ПРИЧИНА}}', `<span class="blue lighten-3">${itemDefect.cause}</span>`);
            } else {
                res = res.replace('{{ПРИЧИНА}}', '<span class="warning">___</span>');
            }
            return res.replace('{{ЗНАЧЕНИЕ}}', replacedString);
        },
        findDefectByName(name){
            for(let i=0;i<this.allDefects.length;i++){
                if(this.allDefects[i]['name'] == name) return this.allDefects[i];
            }
            this.allDefects.push({name:name,surfaces:[]});
            return this.allDefects[this.allDefects.length-1];
        },
        findSurfaceByName(defectObj,name){
            console.log(defectObj)
            for(let i=0;i<defectObj.surfaces.length;i++){
                if(defectObj.surfaces[i].name == name) return defectObj.surfaces[i];
            }
            defectObj.surfaces.push({name:name,defects:[]});
            return defectObj.surfaces[defectObj.surfaces.length-1];
        },
        showEditDefectForm(item){
            console.log(item);
            this.editedDefect = item;
            this.fotoModeEdit = true;
        },
        onConfirmRemovePhotoDefect(id){
            this.dialogConfirmRemovePhoto = true;
            this.removePhotoIsProcces = false;
            this.removedPhotoDefectID = id;
        },
        async removedPhotoDefectConfirmed(){
            this.removePhotoIsProcces = true;
            try{
                let response = await removePhotoDefectByID(this.removedPhotoDefectID);
                if(response.ok){
                    this.$store.dispatch('removePhotoDefectByID',this.removedPhotoDefectID);
                    this.snackSuccess = true;
                    this.snackSuccessText = 'Фотография недостатка удалена';
                } else {
                    let data = await response.json();
                    this.snackError = true;
                    this.snackErrorText = data.message;
                }
            }catch{
                this.snackError = true;
                this.snackErrorText = 'Не удалось выполнить запрос на удаление';
            }finally{
                this.dialogConfirmRemovePhoto = false;
            }


        },
        exitFromEditMode(){
            this.fotoModeEdit = false;
        },
        addDefectSucces(txt){
            this.snackSuccess = true;
            this.snackSuccessText = txt;
            this.fotoModeEdit = false;
        },
        showAddDefects(){
            this.editedDefect = null;
            this.fotoModeEdit = true;
        },
        selectFileClick() {
            this.files = [];
            this.$refs.input.$el.querySelector('input').value = '';
            this.$refs.input.$el.querySelector('input').click();
        },
        async reedFiles() {
            if (this.files.length == 0) {
                alert('Нужно выбрать файл');
                return;
            }
            let formData = new FormData();
            let file = this.files[0];
            formData.append('photo', file);
            formData.append('idInspObj', this.currentDefectObjId);
            try {
                let result = await uploadFotoDefect(formData);
                if (!result.ok) {
                    let text = await result.text();
                    alert(text);
                } else {
                    let json = await result.json();
                    this.$store.dispatch("addDefectFoto",json);
                }

            } catch (e) {
                alert(e.message);
            }
        },
    },
    beforeDestroy(){
        this.$store.commit("setDefectPhotoList", []);
    },
    mounted(){
        // this.$store.dispatch("setDefectPhotoList", []);
    }
}
</script>
<style scoped>
.border-table{
  border-collapse: collapse; /* убираем пустые промежутки между ячейками */
  border: 1px solid grey; /* устанавливаем для таблицы внешнюю границу серого цвета толщиной 1px */
}
.border-table td,
.border-table th{
    border: 1px solid grey;
}
</style>