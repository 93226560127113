<template>
    <div>
        <v-snackbar :timeout="3000" v-model="snackSuccess" fixed bottom right color="success" elevation="24">
            {{ snackSuccessText }}
        </v-snackbar>
        <v-snackbar :timeout="3000" v-model="snackError" fixed bottom right color="error" elevation="24">
            {{ snackErrorText }}
        </v-snackbar>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <RegionsCatalog @msg:success="showSucces" @msg:error="showError" class="mt-4" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src
import RegionsCatalog from '../components/Catalogs/RegionsCatalog';
export default {
    components: {
        RegionsCatalog,
    },
    data: () => ({
        snackSuccess: false,
        snackError: false,
        snackSuccessText: '',
        snackErrorText: ''
    }),
    methods: {
        showSucces(txt) {
            this.snackSuccess = true;
            this.snackSuccessText = txt;
        },
        showError(txt) {
            this.snackError = true;
            this.snackErrorText = txt;
        }
    }
};
</script>
