<template>
  <v-chip
    v-if="isValidStatus"
    class="ma-2"
    :color="chipColor"
    :style="{ color: textColor }"
  >
    {{ statusLabel }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusChip',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    isValidStatus() {
      return this.status !== '' && this.status !== null;
    },
    chipColor() {
      // Возвращает цвет чипса в зависимости от значения статуса
      switch (this.status) {
        case 'Создан':
          return 'orange'; // Оранжевый
        case 'В работе':
          return 'primary'; // Синий
        case 'Выполнен':
          return 'success'; // Зеленый
        case 'Отменён':
          return 'default'; // Серый
        default:
          return 'default'; // Серый по умолчанию
      }
    },
    textColor() {
      // Устанавливает цвет текста: белый для всех цветов, кроме серого, который делает текст чёрным
      return this.chipColor === 'default' ? 'black' : 'white';
    },
    statusLabel() {
      return this.status || 'Приоритет не выбран';
    },
  },
};
</script>