<template>
  <div>
    <v-dialog
      persistent
      max-width="400"
      v-model="confirmRemoveObjectInspection"
    >
        <v-card>
          <v-card-title class="text-h5">
            Подтверждение
          </v-card-title>
          <v-card-text v-if="!removeIsProcces">Удалить объект осмотра?</v-card-text>
          <v-card-text class="d-flex flex-column align-center" v-if="removeIsProcces">
            <v-progress-circular
              indeterminate
              color="red"
            ></v-progress-circular>
            <div class="mt-2 red--text">Удаление...</div>
          </v-card-text>
            <v-card-text class="d-flex flex-column align-center" v-if="!removeIsProcces && removeErrorText">
                <v-alert
                  dense
                  outlined
                  type="error"
                >
                  {{ removeErrorText }}
                </v-alert>
            </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary darken-1"
              text
              @click="confirmRemoveObjectInspection = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="removeFoto"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
          <v-file-input
                ref="input"
                class="d-none"
                accept="image/*"
                dense
                outlined
                v-model="files"
                show-size
                counter
                multiple
                label="Открыть архив с публикацией"
                @change="reedFiles"
        />
        <v-tabs vertical>
          <v-tab>
            <div style="flex:1;text-align:left">
                <v-icon left>
                  mdi-camera-outline
                </v-icon>
                Общие виды
            </div>

          </v-tab>
          <v-tab>
            <div style="flex:1;text-align:left">
              <v-icon left>
                mdi-alert-octagon-outline
              </v-icon>
              Недостатки
            </div>

          </v-tab>
          <v-tab>
            <div style="flex:1;text-align:left">
                <v-icon left>
                  mdi-ruler-square
                </v-icon>
                Линейные размеры
            </div>

          </v-tab>
          <v-tab>
            <div style="flex:1;text-align:left">
              <v-icon left>
                mdi-access-point
              </v-icon>
              Схема объекта
            </div>

          </v-tab>
          <v-tab>
            <div style="flex:1;text-align:left">
              <v-icon left>
                mdi-card-bulleted-outline
              </v-icon>
              Акт осмотра
            </div>

          </v-tab>

          <v-tab-item>
            <v-card class="justify-center" outlined >
              <v-card-text>
                <v-row class="ma-0">
                  <v-col>
                    <AddObjectDialog :objects="this.$store.getters.getObjectsInspection" @add-object="addNewObjectInspection"/>
                  </v-col>
                  <v-col cols="auto" class="align-center">
                      <div style="width:200px">
                        Завершено: {{ objCompliteCount }}
                        <v-progress-linear :value="objComplitePercent"></v-progress-linear>
                      </div>
                  </v-col>
                  <v-col class="text-right">
                      <v-btn color="primary" class="mr-4" :loading="exportLoading" @click="exportDocx">
                        <v-icon left>
                          mdi-download
                        </v-icon>
                        Экспорт в word
                      </v-btn>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="fetchFotos"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            color="primary"
                          >
                            <v-icon>
                              mdi-refresh
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Обновить таблицу</span>
                      </v-tooltip>
                  </v-col>



                </v-row>
                <div v-if="fotolist.length<=0">Нет фотографий объектов осмотра</div>
                    <v-row class="align-stretch" v-if="fotolist.length > 0">
                        <v-col cols="6" class="d-flex"  v-for="(item, i) in fotolist" :key="i">
                        <v-card class="text-center d-flex flex-column flex justify-center">
                            <v-card-title class="pb-0">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="selectFileClick(item.id, i)"
                                    >
                                      <v-icon>mdi-cloud-download-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Загрузить фотографию</span>
                                </v-tooltip>
                              <v-spacer></v-spacer>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="showConfirmRemoveObject(item.id,i)"
                                  >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Удалить объект осмотра</span>
                              </v-tooltip>
                            </v-card-title>
                            <v-card-text v-if="!item.photoPath" class="d-flex flex">
                              <h3 class="text--disabled title align-center justify-center d-flex flex">Фотография отсутствует</h3>
                            </v-card-text>
                            <v-card-text v-if="item.photoPath">
                              <v-img :src="item.photoPath" contain max-height="500"></v-img>
                            </v-card-text>
                            <v-card-title class="text-subtitle-1 text-center flex-column font-italic mt-auto pt-0" >
                            Фото {{ i+1 }}. {{ item.name }} {{ item.numberType == 0?'': item.numberType }}
                            </v-card-title>
                        </v-card>
                        </v-col>
                    </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <Defects/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                Линейные размеры
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                Схема объекта
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                Акт осмотра
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>

  </div>
</template>

<script>
import { getAllInspObject, uploadFoto, removeObjInspection, createNewObjectInspection } from "@/api/expertiseApi";
import { generateDocx } from "../../api/expertiseApi";
import AddObjectDialog from "../ExpertiseEdit/AddObjectDialog";
import Defects from "../Defects/Defects.vue";
export default {
  components:{
    AddObjectDialog,
    Defects
  },
  props: {
    idExpertise: {
      required: true,
    },
  },
  data: () => ({
    removeErrorText:'',
    removeIsProcces:false,
    confirmRemoveObjectInspection: false,
    currentID:'',
    currentIndex:-1,
    files:[],
    imgSrc: "",
    title: "",
    number:"",
    fotolist:[],
    exportLoading: false
  }),
  methods: {
    async exportDocx() {
      this.exportLoading = true;
      let response = await generateDocx(this.$route.params.id);
      if (response.ok) {
        let fileName = 'Документ.docx';
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1];
          }
        }
        let blob = await response.blob();
        const file = new Blob([blob]);
        const fileUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        this.exportLoading = false;
      } else {
        this.exportLoading = false;
      }
    },
    async addNewObjectInspection(name,number) {
      let formData = new FormData();
      formData.append('idExpertise', this.$route.params.id);
      formData.append('name', name);
      formData.append('numberType', number);
      // let data = { 'idExpertise': this.$route.params.id, 'name': name, 'number':number }
      let response = await createNewObjectInspection(formData);
      if (response.ok) {
        let data = await response.json();
        this.addNewOject(data);
      }
    },
    showConfirmRemoveObject(id,currentIndex){
      this.removeErrorText = '';
      this.removeIsProcces = false;
      this.currentID = id;
      this.currentIndex = currentIndex;
      this.confirmRemoveObjectInspection = true;
    },
    async removeFoto(){
      this.removeIsProcces = true;
      let res = await removeObjInspection(this.currentID);
      try{
        if (res.ok) {
          this.fetchFotos();
          this.confirmRemoveObjectInspection = false;
          return;
        } else {
          let errText = await res.text();
          this.removeErrorText = errText;
          this.removeIsProcces = false;
        }
      }catch(e){
        this.removeErrorText = e.message;
        this.removeIsProcces = false;
      }

    },
    selectFileClick(id, currentIndex){
      this.currentIndex = currentIndex;
      this.currentID = id;
      this.files = [];
      this.$refs.input.$el.querySelector('input').value ='';
      this.$refs.input.$el.querySelector('input').click();
    },
    async reedFiles(){
      if(this.files.length==0){
        alert('Нужно выбрать файл');
        return;
      }
      let formData = new FormData();
      let file = this.files[0];
      formData.append('photo', file);
      formData.append('id',this.currentID);
      try{
        let result = await uploadFoto(this.currentID, formData);
        if(!result.ok){
          let text = await result.text();
          alert(text);
        } else {
          this.fotolist[this.currentIndex].photoPath = URL.createObjectURL(file);
        }
      }catch(e){
        alert(e.message);
      }
    },
    async fetchFotos() {
      let res = await getAllInspObject(this.idExpertise);
      if (!res.ok) {
        this.title = "Ошибка";
        return;
      }
      let photos = await res.json();
      if (photos.listInspObjs.length == 0) {
        this.title = "Нет фото";
        return;
      }
      this.fotolist = photos.listInspObjs;
      this.imgSrc = photos.listInspObjs[0].photoPath;
      this.title = photos.listInspObjs[0].name;
      this.number = photos.listInspObjs[0].number;
    },
    addNewOject(data){
      this.fotolist.push(data);
    }
  },
  created() {
    this.fetchFotos();
  },
  computed:{
    objCompliteCount(){
      let res = this.fotolist.filter(function (n) {
        return n.photoPath != '';
      });
      return `${res.length} из ${this.fotolist.length}`;
    },
    objComplitePercent(){
      let all_count = this.fotolist.length;
      let complite_count = this.fotolist.filter(function (n) {
        return n.photoPath != '';
      });
      console.log(this.fotolist.length)
      if(all_count==0) return 0;
      return Math.floor(100 * (complite_count.length/all_count));
    }
  }
};
</script>

<style></style>
